













import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import parseMarkdown from '~/helpers/parseMarkdown';
import RichText from '~/cms/RichText.vue';

export default defineComponent({
  name: 'RichTexts',
  components: {
    RichText
  },
  props: {
    columns: {
      type: Array as PropType<Array<{ richText: string; external?: string }>>,
      default: () => []
    }
  },
  setup(props) {
    const parsedColumns = computed(() => {
      if (!props.columns) {
        return [];
      }
      return props.columns.map(column => ({
        ...column,
        richText: parseMarkdown(column.richText)
      }));
    });
    return {
      parsedColumns
    };
  }
});
